<template>
    <div class="operating_terms_div">
        <div class="nav_div"></div>
        <div class="operating_terms_box">
            <p class="title_p">法律声明</p>
            <!-- <div class="time_div">
                <span class="time_span">2016年6月16日生效</span>
            </div> -->
            <div class="paragraph_div">
                <p>使用本网站前，请您仔细阅读下述条款。如您不同意本服务条款或上海东志国际货物运输代理有限公司(下称"东志国际集团")网站对其进行的修改，您可以主动取消本网站提供的服务；您一旦使用本网站提供的服务，即视为您已了解并完全同意本服务条款各项内容，包括本网站对服务条款所做的任何修改，并成为本网站的用户（以下简称"用户"）</p>
                <p>1、版权归属</p>
                <p>本网站独立拥有东志国际集团网站相关网页内所有资料的版权，或与东志国际集团网站相关网页内的资料提供者共同拥有该网页内资料的版权。未经东志国际集团的明确书面许可，对于东志国际集团网站上的任何内容，任何人不得对其进行复制或在东志国际集团所属的服务器上做镜像。</p>
                <p>2、免责声明</p>
                <p>（1）本网站所发布的信息中可能没有包含与东志国际集团及其业务有关的最新信息，对于本网站所发布的信息，本公司不声明或保证其内容之正确性或完整性；对于用户通过本网站有关服务之广告、信息或要约而购买或取得之任何产品、信息或服务，本公司亦不承担质量保证之责任。</p>
                <p>（2）东志国际集团对本网站提供的服务不提供任何明示或默示的担保或保证，包含但不限于商业适售性、特定目的之适用性及未侵害他人权利等担保或保证。</p>
                <p>（3）用户明确了解并同意，基于以下原因而造成的，包括但不限于利润、信誉、应用、数据损失或其他无形损失，东志国际集团不承担任何直接、间接、附带、特别、衍生性或惩罚性赔偿责任（即使东志国际集团事先已被告知发生此种赔偿之可能性亦然）：</p>
                <p>(i) 本网站提供服务之使用或无法使用；</p>
                <p>(ii) 为替换通过本网站服务购买或取得之任何商品、数据、信息、服务，或缔结的交易而发生的成本；</p>
                <p>(iii) 您的传输或数据遭到未获授权的存取或篡改；</p>
                <p>(iv) 任何第三方在本服务中所作之声明或行为，或；</p>
                <p>(v) 与本服务相关的其他事宜，但本服务条款有明确规定的除外。</p>
                <p>（4）本网站转载的相关文章、资料中表述或暗示的观点不代表东志国际集团的观点。</p>
                <p>（5）本网站所提供的信息，若在某地区使用或分发时会违反该地区的法律或规定，则不得在该地区使用或分发本网站信息。</p>
                <p>（6）本网站遵循互利互惠的原则进行内容合作。所转载的信息部分转载自网络，如无意侵犯您的著作权，请及时与我们联系，我们将第一时间删除相关信息！</p>
                <p>3、隐私权保护</p>
                <p>（1）用户在匿名的状态下即可访问我们的网站并获取信息。在本网站请求用户提供有关信息之前，本网站会解释这些信息的用途，有些服务需要用户注册后才能够提供。在通常情况下，这类注册只要求用户提供一个电子邮件地址和一些诸如用户的工作、职务一类的基本信息。有时本网站也会请用户提供更多的信息，这样做是为了使本网站更好的理解用户的需求，以便向用户提供更为有效的服务。本网站收集的信息包括姓名，地址和移动电话号码等，用户有权随时决定不接受来自我们的任何资料。</p>
                <p>（2）用户的注册义务 用户如希望使用本网站需注册后才能够提供的服务，应同意以下事项∶依本服务注册表之提示提供您本人真实、正确、最新及完整的资料，并随时更新登记资料，确保其为真实、正确、最新及完整的资料。若您提供任何错误、不实、过时或不完整或具误导性的资料，或者本网站有理由怀疑前述资料为错误、不实、过时或不完整或具误导性的，本网站有权暂停或终止您的帐号，并拒绝您于现在和未来使用本网站所提供服务之全部或任何部分。</p>
                <p>（3）本网站将采取合理的安全手段保护用户已存储的个人信息，除非根据法律或政府的强制性规定，在未得到用户许可之前，本网站不会将用户的任何个人信息提供给任何无关的第三方（包括公司或个人）。</p>
                <p>（4）用户应了解：本网站可能会应法律之要求公开个人资料，或者因善意确信这样的作法对于下列各项有其必要性：</p>
                <p>（i）符合法律公告或遵守适用于东志国际集团的合法程序；</p>
                <p>（ii）保护本网站用户的权利或财产；</p>
                <p>（iii）在紧急的情况下，为了保护本网站及其用户之个人或公众安全。</p>
                <p>4、预测性陈述</p>
                <p>本网站所发布的信息中可能会包含某些预测性的陈述。这些陈述本质上具有相当风险和不确定性，对本网站所发布的有关东志国际集团及相关业务的预测性陈述，目的均是要指明其属预测性陈述，本公司并不打算不断地更新这些预测性陈述。此等预测性陈述反映本公司对未来事件的目前看法，而并非关于将来业务表现的保证。</p>
                <p>5、知识产权</p>
                <p>本网站所载述的域名商标、文字、视像及声音内容、图形及图象均受有关商标和著作权的法律保护。未经东志国际集团事先以书面明确允许，任何企业或个人不得以任何形式复制或传递。</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {},
    computed: {},
    mounted() {},
    watch: {},
    methods: {},
    data() {
        return {
            
        }
    }
}
</script>
<style lang="less">
.operating_terms_div{
    .operating_terms_box{
        line-height: 48px;
        margin: 30px 0;
        .title_p{
            font-size: 20px;
            font-weight: 700;
            text-align: center;
        }
        .time_div{
            text-align: center;
            .time_span{
                font-size: 16px;
                // margin: 0 100px 0 0;
            }
        }
        .paragraph_div{
            width: 80%;
            margin: 0 auto;
            p{
                text-indent:24px
            }
        }
    }
}
</style>
